@import "Variables";
@import "Font";
@import "Html_body";

.app-wrapper {
  //  margin-top: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  .app {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;

    .norman-wrapper {
      // display: flex;
      // width: 100% !important;
      // border: ;
      // flex: 1;
      // justify-content: "center";
      // border-radius: $radius;
      // background: "red " !important ;
      // width: calc(100%);
      .norman-text {
        width: auto;

        padding-right: 20px;
        background: $gray;
        font-family: "led16";
        color: $black-500;
        text-transform: uppercase;
        vertical-align: middle;
        text-indent: 10px;
        height: 40px;
        line-height: 40px;
        //   padding: 10px 20px;
      }
    }

    .input-wrapper {
      margin-top: 20px;
      &.input-top {
        margin-top: 5px;
      }
      width: calc(100% - 20px);
      background: #000;
      border-radius: $radius;
      border: 1px solid #5f5f5f;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      justify-items: center;

      &.input-message {
        height: 200px;
      }
      .input-border {
        border: 3px solid $blue-200;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        display: flex;
        justify-content: center;
        border-radius: $radius;
        background: #2b2b2b;

        .input-input {
          width: calc(100% - 40px);
          background: transparent;
          outline: none;
          color: #c10000;
          font-family: "led16";
          font-size: 30px;
          border: none;
          &.input-textarea {
            padding-top: 10px;
          }
        }
      }
    }
  }
}

.button-wrapper {
  margin-top: 20px;
  // width: 100%;
  display: flex;
  justify-content: flex-end;
  .button {
    font-family: "led16";
    padding: 20px 30px;
    border-radius: $radius;
    background: #800000;
    color: #ffd7c4;
    font-weight: bolder;
    font-size: 2rem;
    text-transform: uppercase;
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
