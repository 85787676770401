html,
body {
  padding: 0;
  margin: 0;

  // width: 100vw;
  // height: 100vh;
  // overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
  background: $background;
}
