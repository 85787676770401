@font-face {
  font-family: "DS-DIGI";
  src: url("fonts/ds_digital/DS-DIGI.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DS-DIGI";
  src: url("fonts/ds_digital/DS-DIGIB.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "led16";
  src: url("fonts/led16sgmnt2-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
